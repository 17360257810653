import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { AnalyticsService } from '../../services/analytics.service';
import { get, set } from '../../services/storage.service';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoriteComponent implements OnInit {
  //TODO CAMBIAR ICONO FAVORITOS
  @Input('item') item: any;
  isFavorite = false;
  constructor(
    private analyticsService: AnalyticsService,
    private restService: RestService
  ) {}

  ngOnInit() {
    get('FAVORITES').then((ids) => {
      if (
        ids &&
        ids.findIndex((item) => {
          return (
            item.id === this.item.id &&
            item.typeString === this.item.typeString &&
            item.categoryId === this.item.categoryId
          );
        }) !== -1
      )
        this.isFavorite = true;
    });
  }
  async setFavorite($event, item) {
    $event.preventDefault();
    $event.stopPropagation();
    get('FAVORITES').then((ids) => {
      ids = ids ? ids : [];
      if (this.isFavorite) {
        this.analyticsService.logEvent('setFavoriteOff', {
          CONTENT_TYPE: this.item.typeString,
          ITEM_ID: item.id,
        });
        const index = ids.findIndex((item) => {
          return (
            item.id === this.item.id &&
            item.typeString === this.item.typeString &&
            item.categoryId === this.item.categoryId
          );
        });
        if (index > -1) {
          ids.splice(index, 1);
        }
        this.isFavorite = false;
        this.item.likes--;
      } else {
        this.analyticsService.logEvent('setFavoriteOn', {
          CONTENT_TYPE: this.item.typeString,
          ITEM_ID: item.id,
        });
        if (this.item.typeString == 'notification') {
          this.item['typeStringUrl'] = '/notification';
          this.item['categoryId'] = 'notification';
        }
        ids.push(this.item);
        this.isFavorite = true;
        this.item.likes++;
      }
      set('FAVORITES', ids);
    });
  }
}
